// import * as persistenceWorker from './persistenceWorker';
import { default as setupRulesEngine } from './setupRulesEngine';
import {
    database,
    localStorage,
    sessionStorage,
    metadata,
    loadUseCase,
    network,
    offlineResilientCommunication,
    registerServiceWorker,
    socket
} from 'lib_ui-services';
import log from '@sstdev/lib_logging';
import enableDevtoolCommands from './devToolCommands';

let unsubscribes = [];

/**
 *
 * @param {object} param
 * @param {object} param.user
 * @param {import('lib_ui-services/src/eventSink').EventSink} param.eventSink [ subscribe, publish, request ]
 */
export default async function orchestrateUseCase({ user, eventSink, initialOrchestration = true }) {
    /*
     * #5992. Drop down selections from one tenant were being loaded after user switched to another tenant
     * Added a prefix to local storage keys to prevent cross talk between tenants.
     */
    let prefix = user.activeTenant['identity:tenant']._id + '_'; //underscore is just there to visually separate the prefix
    log.debug(`[ORCHESTRATOR] Setting local storage prefix: ${prefix}`);
    sessionStorage.init();
    localStorage.setPrefix(prefix);
    log.debug(`[ORCHESTRATOR] Getting use case ${user.activeUseCase['metaui:useCase'].title}`);
    // get use case. We have to do this directly,
    // as the persistence worker currently requires the use case in order to be initialized.

    const payload = {
        query: {
            _id: user.activeUseCase['metaui:useCase']._id,
            tenantId: user.activeTenant['identity:tenant']._id,
            role: user['identity:role']?.title || 'USER',
            featureFlags: user.allFeatureFlags || []
        }
    };

    const useCase = await loadUseCase(payload);
    metadata.setMetadata(useCase);

    if (initialOrchestration) {
        log.debug('[ORCHESTRATOR] Registering the service worker');
        //Enable service worker for offline support (noop for native)
        registerServiceWorker.registerMainServiceWorker(eventSink);
        log.debug('[ORCHESTRATOR] Wiring up offline resilient communication to event sink for the current use case');
        await offlineResilientCommunication.initialize(eventSink);
        log.debug('[ORCHESTRATOR] Wiring up persistence to event sink for the current use case');

        await database.initialize(user, eventSink, useCase);
        await socket.connect(eventSink);

        log.debug('[ORCHESTRATOR] Wiring up rules engine to event sink for the current use case');
        // connect rules engine to appropriate rules
        setupRulesEngine(user, eventSink);
    }

    unsubscribes.forEach(unsubscribe => unsubscribe());
    unsubscribes = [network.listenForChange(eventSink)];

    enableDevtoolCommands(eventSink);

    return {
        useCase,
        synchronizeData: async () => {
            log.debug('[ORCHESTRATOR] Synchronizing initial data');
            const [, , request] = eventSink;

            log.debug('[ORCHESTRATOR] Initiating full sync');
            const timeOut = 0; //0 disables timeout.
            const result = await request(
                {},
                { verb: 'update', namespace: 'application', relation: 'useCase', type: 'syncAllDataToLocal' },
                timeOut
            );
            return result;
        }
    };
}

export const orchestrator = {
    orchestrateUseCase
};
